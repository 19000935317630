<template>
  <div class="content">
    <div class="banner">宁美支持中心 解决您的后顾之忧</div>
    <div class="top">
      <div class="title">售后工单</div>
      <div class="top-container">
        <div class="title-1">售后申请信息</div>
        <div class="text">
          <div class="text-left">报修人：</div>
          <div class="text-right">{{ afterSaleInfo.repairer }}</div>
        </div>
        <div class="text">
          <div class="text-left">报修人电话：</div>
          <div class="text-right">{{ afterSaleInfo.repairerPhone }}</div>
        </div>
        <div class="text">
          <div class="text-left">报修人地址：</div>
          <div class="text-right">{{ afterSaleInfo.repairerAddress }}</div>
        </div>
        <div class="text">
          <div class="text-left">主机SN号：</div>
          <div class="text-right">{{ afterSaleInfo.masterSnCode }}</div>
        </div>
        <div class="text" style="margin-bottom: 48px">
          <div class="text-left">报修凭证：</div>
          <div v-for="item in afterSaleInfo.mediaUrlS" style="margin-left: 5px;margin-bottom: 5px">
            <img :src="item" alt="" style="width: 140px; height: 140px;" @click="clickPreviewAfterSaleImg(item)"/>
          </div>
	        <el-dialog v-model="dialogVisibleMediaUrlS">
		        <img w-full :src="dialogImageUrl" alt="Preview Image" />
	        </el-dialog>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom-container">
        <div class="title-1">工单信息</div>
        <div class="text">
          <div class="text-left">工单编号：</div>
          <div class="text-right">{{ afterSaleInfo.workNumber }}</div>
        </div>
        <div class="text">
          <div class="text-left">提交时间：</div>
          <div class="text-right">{{ afterSaleInfo.createdTime }}</div>
        </div>
        <div class="text">
          <div class="text-left">工单备注：</div>
          <div class="text-right">{{ afterSaleInfo.workRemark }}</div>
        </div>
        <div class="text-input">
          <div class="text-left">
            <img
              src="./images/required.png"
              alt=""
              style="width: 11px; height: 12px"
            />
            <div style="margin-left: 7px">处理人：</div>
          </div>
          <div class="text-right">
            <el-input
              v-model="afterSaleInfo.dealer"
              size="large"
              style="width: 240px"
              :disabled="isEdit == true"
              placeholder="请填写您的姓名"
            />
          </div>
        </div>
        <div class="text-input">
          <div class="text-left">
            <img
              src="./images/required.png"
              alt=""
              style="width: 11px; height: 12px"
            />
            <div style="margin-left: 7px">联系电话：</div>
          </div>
          <div class="text-right">
            <el-input
              v-model="afterSaleInfo.dealerMobile"
              maxlength="11"
              size="large"
              style="width: 240px"
              :disabled="isEdit == true"
              placeholder="请填写您的联系电话"
            />
          </div>
        </div>
        <div class="text">
          <div class="text-left">
            <img
              src="./images/required.png"
              alt=""
              style="width: 11px; height: 12px"
            />
            <div style="margin-left: 7px">处理凭证：</div>
          </div>
          <div class="text-right-gray">
            (请上传清晰凭证图片,以便于及时核对，最多5张)
          </div>
        </div>
        <div style="display: flex; margin-top: 20px">
          <div
            v-if="isEdit == false"
          >
            <div class="upload">
              <el-upload
                v-model:file-list="fileList"
                :action="uploadBrand.url"
                :headers="uploadBrand.headers"
                list-type="picture-card"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :limit="5"
                :on-remove="uploadRemove"
                :on-preview="uploadPreview"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
            </div>
          </div>
	        <div v-else>
		        <template v-for="item in afterSaleInfo.mediaUrl2S">
			        <img :src="item" alt="" @click="clickPreviewDisposeImg(item)" style="width: 140px;height: 140px;margin-left: 5px;margin-bottom: 5px">
		        </template>
	        </div>
	        <el-dialog v-model="dialogVisible">
		        <img w-full :src="dialogImageUrl" alt="Preview Image" />
	        </el-dialog>
        </div>
        <div class="text">
          <div class="text-left">处理备注：</div>
        </div>
        <div class="text-remark">
          <el-input
            v-model="afterSaleInfo.remark"
            :disabled="isEdit == true"
            :autosize="{ minRows: 4, maxRows: 8 }"
            type="textarea"
            placeholder="选填，最多支持200字"
            :maxlength="200"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-around;
          margin-top: 20px;
          margin-bottom: 30px;
          width: 100%;
        "
      >
        <div class="button button-back" @click="clickBack">返回</div>
        <div class="button button-submit" @click="clickSubmit">确认提交</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { workCommit, workOrderDetail} from "../../api/work/index.js";
import { ElMessage } from "element-plus";

const route = useRoute();
const router = useRouter();

let afterSaleInfo = ref({}); // 售后信息
let imgFilePath = ref([]); // 上传的图片
let fileList = ref([]);
let workOrderInfo = ref({}); // 工单信息
const workNumber = ref();
let isEdit = ref(false);


// 预览售后图片
let dialogVisibleMediaUrlS = ref(false);
const clickPreviewAfterSaleImg = (fimgUrl) => {
	console.log(fimgUrl)
	dialogImageUrl.value = fimgUrl;
	dialogVisibleMediaUrlS.value = true;
};

const uploadBrand = ref({
  headers: { ciphertext: "CVH3XPy1Swga/M8ZSXQwmo7pondZ+V/q6b1sNv14eNM=" },
  url: "/order/common/anonymous/uploads",
});

onMounted(() => {
  workNumber.value = route.query.workNumber;
  // 获取工单信息
  workOrderDetail(workNumber.value).then((res) => {
    if (res.code == 200) {
      afterSaleInfo.value = res.data;
	    if(res.data.mediaUrl2S != null){
		    isEdit.value = true
	    }
    }
  });
});
// 图片上传成功
const uploadSuccess = (file) => {
  if (file.code == 200) {
    imgFilePath.value.push(file.data.fileNames);
    /*fileList.value.push({
      name: file.data.originalFilenames,
      url: file.data.urls,
    });*/
    console.log("上传成功", imgFilePath.value, fileList.value);
  }
};
// 图片上传失败
const uploadError = () => {
  ElMessage({
    message: "图片上传失败",
    type: "error",
  });
};
// 图片删除
const uploadRemove = (file) => {
  const index = imgFilePath.value.findIndex(
    (item) => item == file.response.data.fileNames
  );
  imgFilePath.value.splice(index);
};
// 预览图片
let dialogVisible = ref(false);
let dialogImageUrl = ref("");
const uploadPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url;
  dialogVisible.value = true;
};

// 查看工单时，预览处理凭证
const clickPreviewDisposeImg = (fimgUrl) => {
  dialogImageUrl.value = fimgUrl;
  dialogVisible.value = true;
};

const clickBack = () => {
  router.push("/");
};
const clickSubmit = () => {
  if (isEdit.value == true) {
    ElMessage({
      message: "该售后工单已提交",
      type: "warning",
    });
  } else {
    if (afterSaleInfo.value.dealer == null || afterSaleInfo.value.dealer == "") {
      ElMessage({
        message: "请输入处理人",
        type: "warning",
      });
    } else if (afterSaleInfo.value.dealerMobile == null || afterSaleInfo.value.dealerMobile == "") {
      ElMessage({
        message: "请输入处理人手机号",
        type: "warning",
      });
    } else if (imgFilePath.value.length == 0) {
      ElMessage({
        message: "请上传处理凭证",
        type: "warning",
      });
    } else {
      workOrderInfo.value.dealer = afterSaleInfo.value.dealer;
      workOrderInfo.value.dealerMobile = afterSaleInfo.value.dealerMobile;
      workOrderInfo.value.remark = afterSaleInfo.value.remark;
      workOrderInfo.value.workNumber = workNumber.value;
      console.log(imgFilePath.value);
      const imgFilePath1 = imgFilePath.value;
      workOrderInfo.value.mediaUrl = imgFilePath1.toString();
	    workCommit(workOrderInfo.value).then((res) => {
        if (res.code == 200) {
          ElMessage({
            message: "提交成功",
            type: "success",
          });
          router.push("/");
        } else {
          ElMessage({
            message: "提交失败",
            type: "error",
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.content {
  width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;

  .banner {
    width: 100%;
    height: 240px;
    line-height: 240px;
    background-image: url("./images/banner.png");
    background-size: cover;
    text-align: center;
    font-family: PingFang SC;
    font-weight: bold;
    font-size: 36px;
    color: #ffffff;
  }

  .top {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
    background: #ffffff;
    border-radius: 10px;

    .title {
      margin-top: 48px;
      margin-bottom: 8px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 34px;
      color: #111111;
    }

    .top-container {
      margin-left: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .title-1 {
        margin-top: 31px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 28px;
        color: #333333;
      }

      .text {
        display: flex;
        margin-top: 20px;
      }

      .text-left {
        width: 145px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
      }

      .text-right {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #666666;
      }
    }
  }

  .bottom {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
    background: #ffffff;
    border-radius: 10px;

    .bottom-container {
      margin-left: 80px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .title-1 {
        margin-top: 47px;
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 28px;
        color: #333333;
      }

      .text {
        display: flex;
        margin-top: 20px;
      }

      .text-input {
        display: flex;
        margin-top: 20px;
        border-bottom: 1px solid #eeeeee;
      }

      .text-left {
        display: flex;
        align-items: center;
        width: 145px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #333333;
      }

      .text-right {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #666666;
      }

      .text-right-placeholder {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #999999;
      }

      .text-right-gray {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #999999;
      }

      .upload {
        // margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        //width: 140px;
        //height: 140px;
        //border: 2px dashed #D9D9D9;

        view {
          margin-top: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #999999;
        }
      }

      .text-remark {
        margin-top: 20px;
        //height: 160px;
	      width: 90%;
        //background-color: #f2f2f2;
        border-radius: 5px;
      }
      uni-textarea {
        width: 95%;
        padding: 21px;
      }
      .uni-textarea-textarea {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #999999;
      }
      ::v-deep .el-textarea__inner {
        font-size: 23px;
      }
      ::v-deep .el-input__inner {
        font-size: 23px;
      }
    }
    .button {
      width: 307px;
      height: 80px;
      line-height: 80px;
      text-align: center;
      background: #ce1200;
      border-radius: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 32px;
      cursor: pointer;
    }
    .button-back {
      background: #f2f2f2;
      color: #333333;
    }
    .button-submit {
      background: #ce1200;
      color: #ffffff;
    }
  }
}
</style>
